import api from '@api/Api';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { languageSpecKeys } from '@hooks/queryKeyFactory';
import { IMember, ILanguageSpec, ILanguageSpecList } from '@common/interfaces';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { getLanguage } from '@common/Code';

type IFilters = {
  startLanguageList?: string[];
  destinationLanguageList?: string[];
  mainLanguageLeaderUserId?: string;
  subLanguageLeaderUserIdList?: string[];
  workUserIdList?: string[];
};

const initialFilters: IFilters = {
  startLanguageList: [''],
  destinationLanguageList: [''],
  mainLanguageLeaderUserId: '',
  subLanguageLeaderUserIdList: [''],
  workUserIdList: ['']
};
/**
 * @description 언어페어 리스트 /languageSpec/list
 * @returns { filterOption: IFilterOptionList,
 *     startLanguageList: string[],
 *     changeStartLanguageList: function,
 *     destinationLanguageList: string[],
 *     changeDestinationLanguageList: function,
 *     mainLanguageLeaderUserId: string,
 *     setMainLanguageLeaderUserId: function,
 *     subLanguageLeaderUserIdList: string[],
 *     changeSubLanguageLeaderUserIdList: function,
 *     workUserIdList: string[],
 *     changeWorkUserIdList: function,
 *     languageSpecList: ILanguageSpec[],
 *     setIsSort: function,
 *     isSort: { [key: string]: any },
 *     clear: function,
 *     clearFlag: boolean }
 */
export const usePostLanguageSpecList = () => {
  //#region state
  const [filterOption, setFilterOption] = useState<{
    startLanguageList: {
      code: string;
      label: string;
    }[];
    destinationLanguageList: {
      code: string;
      label: string;
    }[];
    mainLanguageLeaderList: IMember[];
    subLanguageLeaderList: IMember[];
    workerList: IMember[];
  }>({
    startLanguageList: [],
    destinationLanguageList: [],
    mainLanguageLeaderList: [],
    subLanguageLeaderList: [],
    workerList: []
  });
  const [startLanguageList, setStartLanguageList] = useState<string[]>(['']);
  const [destinationLanguageList, setDestinationLanguageList] = useState<
    string[]
  >(['']);
  const [mainLanguageLeaderUserId, setMainLanguageLeaderUserId] =
    useState<string>('');
  const [subLanguageLeaderUserIdList, setSubLanguageLeaderUserIdList] =
    useState<string[]>(['']);
  const [workUserIdList, setWorkUserIdList] = useState<string[]>(['']);

  const [clearFlag, setClearFlag] = useState(false);
  const [isSort, setIsSort] = useState<{ [key: string]: any }>({
    code: '',
    isAsc: false
  });
  //#endregion

  const changeStartLanguageList = (startLanguage: string) => {
    let startLanguages: string[] = [];
    if (startLanguage === '') {
      startLanguages = [''];
    } else {
      if (startLanguageList.includes(startLanguage)) {
        startLanguages = _.filter(
          startLanguageList,
          (el) => el !== startLanguage
        );
      } else {
        startLanguages = [...startLanguageList, startLanguage];
      }
      startLanguages = _.filter(startLanguages, (el) => el !== '');
    }
    if (startLanguages.length === 0) startLanguages = [''];
    setStartLanguageList(startLanguages);
  };

  const changeDestinationLanguageList = (destinationLanguage: string) => {
    let destinationLanguages: string[] = [];
    if (destinationLanguage === '') {
      destinationLanguages = [''];
    } else {
      if (destinationLanguageList.includes(destinationLanguage)) {
        destinationLanguages = _.filter(
          destinationLanguageList,
          (el) => el !== destinationLanguage
        );
      } else {
        destinationLanguages = [
          ...destinationLanguageList,
          destinationLanguage
        ];
      }
      destinationLanguages = _.filter(destinationLanguages, (el) => el !== '');
    }
    if (destinationLanguages.length === 0) destinationLanguages = [''];
    setDestinationLanguageList(destinationLanguages);
  };

  const changeSubLanguageLeaderUserIdList = (
    subLanguageLeaderUserId: string
  ) => {
    let subLanguageLeaderUserIds: string[] = [];
    if (subLanguageLeaderUserId === '') {
      subLanguageLeaderUserIds = [''];
    } else {
      if (subLanguageLeaderUserIdList.includes(subLanguageLeaderUserId)) {
        subLanguageLeaderUserIds = _.filter(
          subLanguageLeaderUserIdList,
          (el) => el !== subLanguageLeaderUserId
        );
      } else {
        subLanguageLeaderUserIds = [
          ...subLanguageLeaderUserIdList,
          subLanguageLeaderUserId
        ];
      }
      subLanguageLeaderUserIds = _.filter(
        subLanguageLeaderUserIds,
        (el) => el !== ''
      );
    }
    if (subLanguageLeaderUserIds.length === 0) subLanguageLeaderUserIds = [''];
    setSubLanguageLeaderUserIdList(subLanguageLeaderUserIds);
  };

  const changeWorkUserIdList = (workUserId: string) => {
    let workUserIds: string[] = [];
    if (workUserId === '') {
      workUserIds = [''];
    } else {
      if (workUserIdList.includes(workUserId)) {
        workUserIds = _.filter(workUserIdList, (el) => el !== workUserId);
      } else {
        workUserIds = [...workUserIdList, workUserId];
      }
      workUserIds = _.filter(workUserIds, (el) => el !== '');
    }
    if (workUserIds.length === 0) workUserIds = [''];
    setWorkUserIdList(workUserIds);
  };

  const clear = () => {
    const {
      startLanguageList,
      destinationLanguageList,
      mainLanguageLeaderUserId,
      subLanguageLeaderUserIdList,
      workUserIdList
    } = initialFilters;
    setStartLanguageList(startLanguageList as string[]);
    setDestinationLanguageList(destinationLanguageList as string[]);
    setMainLanguageLeaderUserId(mainLanguageLeaderUserId as string);
    setSubLanguageLeaderUserIdList(subLanguageLeaderUserIdList as string[]);
    setWorkUserIdList(workUserIdList as string[]);
  };

  //#region api call
  const getFilterData = async () => {
    return await api.get('/languageSpec/searchFilter').then((res) => res.data);
  };
  const getData = async ({
    queryKey
  }: QueryFunctionContext<
    ReturnType<(typeof languageSpecKeys)['list']>
  >): Promise<ILanguageSpecList> => {
    const [, filter] = queryKey;
    let payload: IFilters = {};
    if (filter.startLanguageList && filter.startLanguageList[0])
      payload.startLanguageList = filter.startLanguageList;
    if (filter.destinationLanguageList && filter.destinationLanguageList[0])
      payload.destinationLanguageList = filter.destinationLanguageList;
    if (filter.mainLanguageLeaderUserId)
      payload.mainLanguageLeaderUserId = filter.mainLanguageLeaderUserId;
    if (
      filter.subLanguageLeaderUserIdList &&
      filter.subLanguageLeaderUserIdList[0]
    )
      payload.subLanguageLeaderUserIdList = filter.subLanguageLeaderUserIdList;
    if (filter.workUserIdList && filter.workUserIdList[0])
      payload.workUserIdList = filter.workUserIdList;
    return await api
      .post('/languageSpec/list', payload)
      .then((res) => res.data);
  };
  //#endregion

  //#region useQuery define
  const { data: searchFilterData } = useQuery(
    [...languageSpecKeys.searchFilter],
    getFilterData
  );
  const {
    data: { languageSpecList }
  } = useQuery(
    [
      ...languageSpecKeys.list({
        startLanguageList,
        destinationLanguageList,
        mainLanguageLeaderUserId,
        subLanguageLeaderUserIdList,
        workUserIdList
      })
    ],
    getData,
    {
      initialData: () => ({
        languageSpecList: []
      }),
      select: (data) => {
        const copyData = _.map(data.languageSpecList, (item: any) => {
          return {
            ...item,
            startLanguageKO: getLanguage([item.startLanguage])[0].label,
            destinationLanguageKO: getLanguage([item.destinationLanguage])[0]
              .label
          };
        });
        return {
          languageSpecList: isSort.code.length
            ? isSort.code === 'languagePair'
              ? ([
                  ..._.orderBy(
                    copyData,
                    [
                      (item) =>
                        item.startLanguageKO + item.destinationLanguageKO
                    ],
                    [isSort.isAsc ? 'asc' : 'desc']
                  )
                ] as ILanguageSpec[])
              : ([
                  ..._.orderBy(
                    data.languageSpecList,
                    [isSort.code],
                    [isSort.isAsc ? 'asc' : 'desc']
                  )
                ] as ILanguageSpec[])
            : data.languageSpecList
        };
      }
    }
  );
  //#endregion

  useEffect(() => {
    if (searchFilterData) {
      const {
        startLanguageList,
        destinationLanguageList,
        mainLanguageLeaderList,
        subLanguageLeaderList,
        workerList
      } = searchFilterData;
      setFilterOption({
        startLanguageList: [
          { code: '', label: '전체' },
          ..._.map(
            startLanguageList,
            (
              item,
              index
            ): {
              code: string;
              label: string;
            } => {
              return { code: item.value, label: item.key };
            }
          )
        ],
        destinationLanguageList: [
          { code: '', label: '전체' },
          ..._.map(
            destinationLanguageList,
            (
              item,
              index
            ): {
              code: string;
              label: string;
            } => {
              return { code: item.value, label: item.key };
            }
          )
        ],
        mainLanguageLeaderList: [
          { userId: '', name: '전체', avatar: '' },
          ...mainLanguageLeaderList
        ],
        subLanguageLeaderList: [
          { userId: '', name: '전체', avatar: '' },
          ...subLanguageLeaderList
        ],
        workerList: [{ userId: '', name: '전체', avatar: '' }, ...workerList]
      });
    }
  }, [searchFilterData]);

  useEffect(() => {
    const filter = {
      startLanguageList,
      destinationLanguageList,
      mainLanguageLeaderUserId,
      subLanguageLeaderUserIdList,
      workUserIdList
    };
    if (JSON.stringify(initialFilters) === JSON.stringify(filter)) {
      setClearFlag(true);
    } else {
      setClearFlag(false);
    }
    return () => {};
  }, [
    startLanguageList,
    destinationLanguageList,
    mainLanguageLeaderUserId,
    subLanguageLeaderUserIdList,
    workUserIdList
  ]);

  return {
    filterOption,
    startLanguageList,
    changeStartLanguageList,
    destinationLanguageList,
    changeDestinationLanguageList,
    mainLanguageLeaderUserId,
    setMainLanguageLeaderUserId,
    subLanguageLeaderUserIdList,
    changeSubLanguageLeaderUserIdList,
    workUserIdList,
    changeWorkUserIdList,
    languageSpecList,
    setIsSort,
    isSort,
    clear,
    clearFlag
  };
};
