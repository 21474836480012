import Api from '@src/api/Api';

export const getCompanyTerm = async (companyId: string, projectKey: string) => {
  const response = await Api.get(`/projectManage/companyTerm/${companyId}`, {
    projectKey: projectKey
  });
  return {
    startDateTime: response.data.startDateTime,
    endDateTime: response.data.endDateTime
  };
};
