import api from '@api/Api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@hooks/modal/useToast';
import { useAlert } from '@hooks/modal/useAlert';
import { useEffect, useState } from 'react';
import { useGetCompanyNameValid } from '@hooks/company/getCompanyNameValid';
import { usePageFunc } from '../utils';

/**
 * /company
 * @description 고객사 등록
 * @returns {
 *     companyName: string,
 *     setCompanyName: function,
 *     companyNameValidCheck: function,
 *     iconImgUrl: string,
 *     setIconImgUrl: function,
 *     iconImgUrlValidCheck: function,
 *     viewUrl: string,
 *     validInfo: { companyName: string, iconImgUrl: string},
 *     clickCreate: function,
 *     closeModal: function
 *   }
 */
export const usePostCompany = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { openHandle: alertHandle } = useAlert();
  const { openHandle } = useToast();
  const { getDateTime } = usePageFunc();
  const {
    companyNameValidData,
    companyNameValid,
    validCompanyNameError,
    validCompanyName
  } = useGetCompanyNameValid();

  const [companyName, setCompanyName] = useState('');
  const [iconImgUrl, setIconImgUrl] = useState('');
  const [viewUrl, setViewUrl] = useState('');
  const [dateFilter, setDateFilter] = useState<{
    startDateTime: string;
    endDateTime: string;
  }>({
    startDateTime: '',
    endDateTime: ''
  });
  const [validInfo, setValidInfo] = useState({
    companyName: '',
    iconImgUrl: '',
    dateFilter: ''
  });

  const changeCompanyName = (value: string) => {
    setCompanyName(value);
    setValidInfo({
      ...validInfo,
      companyName: ''
    });
  };

  const changeIconImgUrl = (value: string) => {
    setIconImgUrl(value);
    setValidInfo({
      ...validInfo,
      iconImgUrl: ''
    });
  };

  const companyNameValidCheck = () => {
    companyNameValid(companyName);
  };

  const iconImgUrlValidCheck = () => {
    const regex = /^(http|https):\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$/;
    if (iconImgUrl) {
      const valid = regex.test(iconImgUrl);
      setValidInfo({
        ...validInfo,
        iconImgUrl: valid ? 'valid' : 'invalid:regex'
      });
      if (valid) setViewUrl(iconImgUrl);
    } else {
      setValidInfo({
        ...validInfo,
        iconImgUrl: 'invalid'
      });
      return;
    }
  };

  const clickCreate = () => {
    alertHandle({
      title: '등록 확인',
      text: '입력한 내용으로 등록하시겠습니까?',
      cancelLabel: '취소',
      ok: () => {
        createCompany();
        navigate('/company', { replace: true });
      }
    });
  };

  const closeModal = () => {
    alertHandle({
      title: '작성 취소',
      text: '입력 중인 내용이 모두 삭제됩니다.',
      cancelLabel: '취소',
      ok: () => {
        navigate('/company', { replace: true });
      }
    });
  };
  //#region api call
  const getData = async () => {
    return await api
      .post(`/company`, {
        companyName,
        iconImgUrl,
        startDateTime: getDateTime({
          date: new Date(dateFilter.startDateTime),
          filter: 'start'
        }),
        endDateTime: getDateTime({
          date: new Date(dateFilter.endDateTime),
          filter: 'end'
        })
      })
      .then((res) => res.data);
  };
  //#endregion

  //#region useQuery define
  const { mutate: createCompany } = useMutation({
    mutationFn: getData,
    onSuccess: () => {
      queryClient.invalidateQueries(['/company/list']);
      openHandle({
        text: `고객사가 등록 되었습니다.`,
        severity: 'success'
      });
    },
    onError: () => {
      openHandle({
        text: `고객사가 등록에 실패하였습니다.`,
        severity: 'error'
      });
    }
  });

  //#endregion

  useEffect(() => {
    if (validCompanyName) {
      const err = validCompanyNameError as any;
      if (err.status === 400) {
        setValidInfo({
          ...validInfo,
          companyName: 'invalid'
        });
      } else {
        setValidInfo({
          ...validInfo,
          companyName: 'invalid:regex'
        });
      }
    } else {
      if (companyNameValidData?.status === 200) {
        setValidInfo({
          ...validInfo,
          companyName: 'valid'
        });
      }
    }
    return () => {};
  }, [companyNameValidData, validCompanyName]);

  useEffect(() => {
    if (dateFilter.startDateTime && dateFilter.endDateTime) {
      setValidInfo({
        ...validInfo,
        dateFilter: 'valid'
      });
    } else {
      setValidInfo({
        ...validInfo,
        dateFilter: 'invalid'
      });
    }
    return () => {};
  }, [dateFilter]);

  return {
    companyName,
    changeCompanyName,
    companyNameValidCheck,
    iconImgUrl,
    changeIconImgUrl,
    iconImgUrlValidCheck,
    viewUrl,
    validInfo,
    dateFilter,
    setDateFilter,
    clickCreate,
    closeModal
  };
};
